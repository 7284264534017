<template>
  <div class="box">
    <topMenu :index="2"></topMenu>
    <div class="content">
      <img class="wen1" src="../../src/assets/image/wen1.png" alt="" />
      <img class="wen2" src="../../src/assets/image/wen1.png" alt="" />
      <div style="position: relative; width: 55%; margin: auto">
        <div style="width: 100%; margin: 40px auto 0; text-align: center">
          <span style="font-size: 28px; line-height: 45px; font-weight: bold">{{ content?.title || '' }}</span>
        </div>
        <img
          v-if="content.img"
          style="display: block; width: 100%; height: 500px; margin: 20px auto"
          :src="config.ossUrl + content.img"
          alt=""
        />
        <div style="margin: auto; box-sizing: border-box; padding: 30rpx">
          <div
            style="font-size: 16px; text-indent: 2em; line-height: 40px; text-align: left; text-align: justify"
            v-html="content.content"
          ></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import config from '../config/config'
import topMenu from '@/components/topMenu.vue'
import Footer from '../components/Footer.vue'
import { contentById } from '../api/index'
export default {
  components: {
    topMenu,
    Footer,
  },
  data() {
    return {
      config,
      state: null,
      id: '',
      content: '',
    }
  },
  methods: {
    getInfo(id) {
      contentById(id).then(res => {
        // console.log("res", res);
        this.content = res.data
        // this.subtitle = res.data.news;
      })
    },
  },
  mounted() {
    console.log('eeee', this.$route.query)
    if (this.$route.query) {
      this.id = this.$route.query.section
      this.getInfo(this.id)
    }
  },
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  position: relative;
  margin: 20px auto 50px;

  .wen1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 530px;
    height: 408px;
  }

  .wen2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 610px;
    height: 407px;
  }
}

.pageText {
  // width: 90%;
  margin: auto;
  box-sizing: border-box;
  padding: 30rpx;

  div {
    // margin-top: 30px;
    font-size: 20px;
    text-indent: 2em;
    line-height: 40px;
    text-align: left;
    text-align: justify;
  }
}
</style>
